import { Room, RoomMember, User } from "matrix-js-sdk/src/matrix";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";

import { UserPillPart } from "../../editor/parts";

const DEACTIVATED_NAME = "Deactivated User";

export interface UserMention {
    userId: string;
    displayName: string;
}

export async function isDeletedUser(userId: string): Promise<boolean> {
    // Call API to check info user
    const cli = MatrixClientPeg.safeGet();
    const profile = await cli.getProfileInfo(userId).catch(() => { return {}; });
    // profile { } -> user deleted
    return !profile || !Object.keys(profile).length;
}

export function isDeactivatedUser(user: User | RoomMember): boolean {
    const rawDisplayName = user?.rawDisplayName;
    if (!rawDisplayName) {
        return false;
    }
    return rawDisplayName === DEACTIVATED_NAME;
}

export function extractUserIds(text: string): UserMention[] {
    const users: UserMention[] = [];
    const regex = /<a[^>]+href="[^"]*\/user\/(@[^:]+:[^"]+)"[^>]*>(.*?)<\/a>/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        users.push({
            userId: match[1],
            displayName: match[2],
        });
    }

    return users;
}

function updateAvatarLetterStyle(style: string | null): string | undefined {
    // character "&ZeroWidthSpace;" for show avatar to center
    return style?.replace(/--avatar-letter:\s*''/, `--avatar-letter: '&ZeroWidthSpace;'`);
}

export function applyStylesToUserLinks(content: string, body: { userId: string; style: string; }[]): string {
    body.forEach(({ userId, style }) => {
        const regex = new RegExp(`(<a\\s+[^>]*href=["'][^"']*\\/user\\/${userId}["'][^>]*)(>)`, 'g');

        content = content.replace(regex, `$1 style="${style}"$2`);
    });

    return content;
}

export function processUserMentions(contentData: string, room?: Room): string {
    const userMentions: UserMention[] = extractUserIds(contentData);
    const replacements: any = [];

    userMentions.forEach(user => {
        const member = room?.getMember(user.userId);
        const userPillPart = new UserPillPart(user.userId, user.displayName, member || undefined);
        const node = userPillPart.toDOMNode();

        if (node instanceof HTMLElement) {
            const style = updateAvatarLetterStyle(node.getAttribute('style'));

            replacements.push({
                userId: user.userId,
                style: style,
            });
        }
    });

    return applyStylesToUserLinks(contentData, replacements);
}
