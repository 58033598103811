import EditorModel from "matrix-react-sdk/src/editor/model";
import { MatrixClient } from "matrix-js-sdk/src/matrix";
import { CommandPartCreator } from "matrix-react-sdk/src/editor/parts";

import { Part } from "../../editor/parts";

export function createMockEditorModel(parts: Part[] = [], mxClient: MatrixClient): EditorModel{
    const fakeRoom : any = {};
    const partCreator = new CommandPartCreator(fakeRoom, mxClient);

    return new EditorModel(parts, partCreator);
}
