import React from "react";
import DialogButtons from "matrix-react-sdk/src/components/views/elements/DialogButtons";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";

import { _t } from "../../../../../../../languageHandler";

interface IProps {
    exceedCount: number;
    onFinished(proceed?: boolean): void;
}

const CharacterLimitDialog: React.FC<IProps> = ({ exceedCount, onFinished }) => {
    const onConfirm = (): void => {
        onFinished(true);
    };

    const onCancel = (): void => {
        onFinished(false);
    };

    return (
        <BaseDialog
            className="ck_CharacterLimitDialog"
            onFinished={onFinished}
            title={SdkConfig.get().brand}
            fixedWidth={false}
        >
            <div className="ck_Dialog_content">
                <div className="ck_CharacterLimitDialog_description">
                    <div>
                        {_t("ctalk|composer|character_limit_dialog|mess_too_long")}
                    </div>
                    <div>
                        {_t("ctalk|composer|character_limit_dialog|exceed_count", {exceedCount})}
                    </div>
                </div>
            </div>
            <DialogButtons
                primaryButton={_t("action|confirm")}
                onPrimaryButtonClick={onConfirm}
                cancelButton={_t("action|cancel")}
                onCancel={onCancel}
            />
        </BaseDialog>
    );
};

export default CharacterLimitDialog;
