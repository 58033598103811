/*
Copyright 2020, 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { MatrixEventEvent, RoomEvent, ClientEvent } from "matrix-js-sdk/src/matrix";
// import type { Room, MatrixEvent } from "matrix-js-sdk/src/matrix";
// import type { IDestroyable } from "matrix-react-sdk/src/utils/IDestroyable";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { readReceiptChangeIsFor } from "matrix-react-sdk/src/utils/read-receipts";
import * as RoomNotifs from "matrix-react-sdk/src/RoomNotifs";
// import { NotificationState } from "matrix-react-sdk/src/stores/notifications/NotificationState";
// import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { NotificationCount } from "matrix-js-sdk/src/models/room";
import { NotificationColor } from "matrix-react-sdk/src/stores/notifications/NotificationColor";
// CTalk imported
import { isServerNoticeRoom } from "@ctalk/helpers/RoomHelper";

import type { Room, MatrixEvent } from "matrix-js-sdk/src/matrix";
import type { IDestroyable } from "matrix-react-sdk/src/utils/IDestroyable";
import { NotificationState, NotificationStateEvents } from "./NotificationState";
import SettingsStore from "../../settings/SettingsStore";

export class RoomNotificationState extends NotificationState implements IDestroyable {
    public constructor(public readonly room: Room) {
        super();
        const cli = this.room.client;
        this.room.on(RoomEvent.Receipt, this.handleReadReceipt);
        this.room.on(RoomEvent.MyMembership, this.handleMembershipUpdate);
        this.room.on(RoomEvent.LocalEchoUpdated, this.handleLocalEchoUpdated);
        this.room.on(RoomEvent.Timeline, this.handleRoomEventUpdate);
        this.room.on(RoomEvent.Redaction, this.handleRoomEventUpdate);

        this.room.on(RoomEvent.UnreadNotifications, this.handleNotificationCountUpdate); // for server-sent counts
        cli.on(MatrixEventEvent.Decrypted, this.onEventDecrypted);
        cli.on(ClientEvent.AccountData, this.handleAccountDataUpdate);
        this.updateNotificationState();
    }

    public destroy(): void {
        super.destroy();
        const cli = this.room.client;
        this.room.removeListener(RoomEvent.Receipt, this.handleReadReceipt);
        this.room.removeListener(RoomEvent.MyMembership, this.handleMembershipUpdate);
        this.room.removeListener(RoomEvent.LocalEchoUpdated, this.handleLocalEchoUpdated);
        this.room.removeListener(RoomEvent.Timeline, this.handleRoomEventUpdate);
        this.room.removeListener(RoomEvent.Redaction, this.handleRoomEventUpdate);
        cli.removeListener(MatrixEventEvent.Decrypted, this.onEventDecrypted);
        cli.removeListener(ClientEvent.AccountData, this.handleAccountDataUpdate);
    }

    private handleLocalEchoUpdated = (): void => {
        this.updateNotificationState();
    };

    private handleReadReceipt = (event: MatrixEvent, room: Room): void => {
        if (!readReceiptChangeIsFor(event, MatrixClientPeg.safeGet())) return; // not our own - ignore
        if (room.roomId !== this.room.roomId) return; // not for us - ignore
        this.updateNotificationState();
    };

    private handleMembershipUpdate = (): void => {
        this.updateNotificationState();
    };

    private handleNotificationCountUpdate = (unreadNotifications?: NotificationCount, threadId?: string): void => {
        // CTalk added
        const isCountReset = unreadNotifications?.total === -1 && unreadNotifications?.highlight === -1;
        if (isCountReset) { // Case reset count
            this.handleResetCount();
            return;
        }
        this.updateNotificationState(true);

        // CTalk hide this
        // this.updateNotificationState();
    };

    private onEventDecrypted = (event: MatrixEvent): void => {
        if (event.getRoomId() !== this.room.roomId) return; // ignore - not for us or notifications timeline
        this.updateNotificationState();
    };

    private handleRoomEventUpdate = (event: MatrixEvent): void => {
        if (event?.getRoomId() !== this.room.roomId) return; // ignore - not for us or notifications timeline
        this.updateNotificationState();
    };

    private handleAccountDataUpdate = (ev: MatrixEvent): void => {
        if (ev.getType() === "m.push_rules") {
            this.updateNotificationState();
        }
    };

    // CTalk added
    private handleResetCount(): void {
        const muted =
            RoomNotifs.getRoomNotifsState(this.room.client, this.room.roomId) === RoomNotifs.RoomNotifState.Mute;
        const knocked = SettingsStore.getValue("feature_ask_to_join") && this.room.getMyMembership() === "knock";
        this._color = NotificationColor.None;
        this._symbol = null;
        this._count = 0;
        this._muted = muted;
        this._knocked = knocked;
        this._pausedUpdate = true;
        if (isServerNoticeRoom(this.room.roomId)) {
            this.emit(NotificationStateEvents.Update);
        }
    }

    /* CTalk hide this
    private updateNotificationState(): void {
        const snapshot = this.snapshot();

        const { color, symbol, count } = RoomNotifs.determineUnreadState(this.room);
        const muted =
            RoomNotifs.getRoomNotifsState(this.room.client, this.room.roomId) === RoomNotifs.RoomNotifState.Mute;
        const knocked = SettingsStore.getValue("feature_ask_to_join") && this.room.getMyMembership() === "knock";
        this._color = color;
        this._symbol = symbol;
        this._count = count;
        this._muted = muted;
        this._knocked = knocked;

        // finally, publish an update if needed
        this.emitIfUpdated(snapshot);
    }
     */

    // CTalk added
    private updateNotificationState(countCleared = false): void {
        const snapshot = this.snapshot();
        const { color, symbol, count } = RoomNotifs.determineUnreadState(this.room);
        const muted = RoomNotifs.getRoomNotifsState(this.room.client, this.room.roomId) === RoomNotifs.RoomNotifState.Mute;
        const knocked = SettingsStore.getValue("feature_ask_to_join") && this.room.getMyMembership() === "knock";

        if (this.pausedUpdate && !countCleared) {
            this._color = NotificationColor.None;
            this._symbol = null;
            this._count = 0;
            this._muted = muted;
            this._knocked = knocked;

            this.emitIfUpdated(snapshot);
            return;
        }

        if (countCleared && count === 0) {
            this._pausedUpdate = false;
        }

        this._color = color;
        this._symbol = symbol;
        this._count = count;
        this._muted = muted;
        this._knocked = knocked;

        this.emitIfUpdated(snapshot);
    }
}
