/*
Copyright 2021 - 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { logger } from "matrix-js-sdk/src/logger";
import { EventTimeline, EventType, Room } from "matrix-js-sdk/src/matrix";
import React, { useContext } from "react";
import { KeyBindingAction } from "matrix-react-sdk/src/accessibility/KeyboardShortcuts";
import RoomListActions from "matrix-react-sdk/src/actions/RoomListActions";
import MatrixClientContext from "matrix-react-sdk/src/contexts/MatrixClientContext";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import { useEventEmitterState } from "matrix-react-sdk/src/hooks/useEventEmitter";
import { useUnreadNotifications } from "matrix-react-sdk/src/hooks/useUnreadNotifications";
import { getKeyBindingsManager } from "matrix-react-sdk/src/KeyBindingsManager";
import { NotificationColor } from "matrix-react-sdk/src/stores/notifications/NotificationColor";
import { DefaultTagID, TagID } from "matrix-react-sdk/src/stores/room-list/models";
import RoomListStore, { LISTS_UPDATE_EVENT } from "matrix-react-sdk/src/stores/room-list/RoomListStore";
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import { clearRoomNotification } from "matrix-react-sdk/src/utils/notifications";
import { IProps as IContextMenuProps } from "matrix-react-sdk/src/components/structures/ContextMenu";
import IconizedContextMenu, {
    IconizedContextMenuCheckbox,
    IconizedContextMenuOption,
    IconizedContextMenuOptionList,
} from "matrix-react-sdk/src/components/views/context_menus/IconizedContextMenu";
import { ButtonEvent } from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { shouldShowComponent } from "matrix-react-sdk/src/customisations/helpers/UIComponents";
import { UIComponent } from "matrix-react-sdk/src/settings/UIFeature";
import { DeveloperToolsOption } from "matrix-react-sdk/src/components/views/context_menus/DeveloperToolsOption";
import { useSettingValue } from "matrix-react-sdk/src/hooks/useSettings";
// CTalk imported
import { RoomSettingsTab } from "matrix-react-sdk/src/components/views/dialogs/RoomSettingsDialog";
import { hasPermission } from '@ctalk/roles/PermissionView';
import { ERoomRole } from "@ctalk/enums/room-role.enum";
import { isDeletedOrDeactivatedDM } from "@ctalk/helpers/RoomHelper";

import { IPowerLevelsContent } from '../right_panel/UserInfo';
import { isActiveLegacyCall, isUserRoleInRoom } from "../../../ctalk/helpers/RoomHelper";
import { _t } from "../../../languageHandler";

export interface RoomGeneralContextMenuProps extends IContextMenuProps {
    room: Room;
    onPostFavoriteClick?: (event: ButtonEvent) => void;
    onPostLowPriorityClick?: (event: ButtonEvent) => void;
    onPostInviteClick?: (event: ButtonEvent) => void;
    onPostCopyLinkClick?: (event: ButtonEvent) => void;
    onPostSettingsClick?: (event: ButtonEvent) => void;
    onPostForgetClick?: (event: ButtonEvent) => void;
    onPostLeaveClick?: (event: ButtonEvent) => void;
}

/**
 * Room context menu accessible via the room list.
 */
export const RoomGeneralContextMenu: React.FC<RoomGeneralContextMenuProps> = ({
    room,
    onFinished,
    onPostFavoriteClick,
    onPostLowPriorityClick,
    onPostInviteClick,
    onPostCopyLinkClick,
    onPostSettingsClick,
    onPostLeaveClick,
    onPostForgetClick,
    ...props
}) => {
    const cli = useContext(MatrixClientContext);
    const roomTags = useEventEmitterState(RoomListStore.instance, LISTS_UPDATE_EVENT, () =>
        RoomListStore.instance.getTagsForRoom(room),
    );
    const isDm = DMRoomMap.shared().getUserIdForRoomId(room.roomId);
    const wrapHandler = (
        handler: (ev: ButtonEvent) => void,
        postHandler?: (ev: ButtonEvent) => void,
        persistent = false,
    ): ((ev: ButtonEvent) => void) => {
        return (ev: ButtonEvent) => {
            ev.preventDefault();
            ev.stopPropagation();

            handler(ev);

            const action = getKeyBindingsManager().getAccessibilityAction(ev as React.KeyboardEvent);
            if (!persistent || action === KeyBindingAction.Enter) {
                onFinished();
            }
            postHandler?.(ev);
        };
    };

    const onTagRoom = (ev: ButtonEvent, tagId: TagID): void => {
        if (!cli) return;
        if (tagId === DefaultTagID.Favourite || tagId === DefaultTagID.LowPriority) {
            const inverseTag = tagId === DefaultTagID.Favourite ? DefaultTagID.LowPriority : DefaultTagID.Favourite;
            const isApplied = RoomListStore.instance.getTagsForRoom(room).includes(tagId);
            const removeTag = isApplied ? tagId : inverseTag;
            const addTag = isApplied ? null : tagId;
            dis.dispatch(RoomListActions.tagRoom(cli, room, removeTag, addTag, 0));
            // CTalk added
            onFinished();
        } else {
            logger.warn(`Unexpected tag ${tagId} applied to ${room.roomId}`);
        }
    };

    const isFavorite = roomTags.includes(DefaultTagID.Favourite);
    const favoriteOption: JSX.Element = (
        <IconizedContextMenuCheckbox
            onClick={wrapHandler((ev) => onTagRoom(ev, DefaultTagID.Favourite), onPostFavoriteClick, true)}
            active={isFavorite}
            label={isFavorite ? _t("room|context_menu|unfavourite") : _t("room|context_menu|favourite")}
            iconClassName="mx_RoomGeneralContextMenu_iconStar"
        />
    );

    // CTalk hidden
    /* const isLowPriority = roomTags.includes(DefaultTagID.LowPriority);
    const lowPriorityOption: JSX.Element = (
        <IconizedContextMenuCheckbox
            onClick={wrapHandler((ev) => onTagRoom(ev, DefaultTagID.LowPriority), onPostLowPriorityClick, true)}
            active={isLowPriority}
            label={_t("room|context_menu|low_priority")}
            iconClassName="mx_RoomGeneralContextMenu_iconArrowDown"
        />
    ); */

    let inviteOption: JSX.Element | null = null;
    if (room.canInvite(cli.getUserId()!) && !isDm && shouldShowComponent(UIComponent.InviteUsers)) {
        inviteOption = (
            <IconizedContextMenuOption
                onClick={wrapHandler(
                    () =>
                        dis.dispatch({
                            action: "view_invite",
                            roomId: room.roomId,
                        }),
                    onPostInviteClick,
                )}
                label={_t("action|invite")}
                iconClassName="mx_RoomGeneralContextMenu_iconInvite"
            />
        );
    }

    let copyLinkOption: JSX.Element | null = null;
    if (!isDm) {
        copyLinkOption = (
            <IconizedContextMenuOption
                onClick={wrapHandler(
                    () =>
                        dis.dispatch({
                            action: "copy_room",
                            room_id: room.roomId,
                        }),
                    onPostCopyLinkClick,
                )}
                label={_t("room|context_menu|copy_link")}
                iconClassName="mx_RoomGeneralContextMenu_iconCopyLink"
            />
        );
    }

    // CTalk added
    let autoDeleteButton: JSX.Element | null = null;
    const roomMember = room.getMember(cli.getSafeUserId());
    const roomState = room.getLiveTimeline().getState(EventTimeline.FORWARDS);
    const powerLevelsEvent = roomState?.getStateEvents(EventType.RoomPowerLevels, "");
    const powerLevels = powerLevelsEvent && powerLevelsEvent.getContent() as IPowerLevelsContent;
    if (hasPermission(roomMember, powerLevels, 'state_default')) {
        autoDeleteButton = (
            <IconizedContextMenuOption
                onClick={wrapHandler(
                    () =>
                        dis.dispatch({
                            action: "open_room_settings",
                            room_id: room.roomId,
                            initial_tab_id: RoomSettingsTab.Security
                        }),
                    onPostSettingsClick,
                )}
                label={_t("ctalk|settings|security|auto_delete_messages|title")}
                iconClassName="ctalk_RoomGeneralContextMenu_iconAutoDelete"
            />
        );
    }

    const settingsOption: JSX.Element = (
        <IconizedContextMenuOption
            onClick={wrapHandler(
                () =>
                    dis.dispatch({
                        action: "open_room_settings",
                        room_id: room.roomId,
                    }),
                onPostSettingsClick,
            )}
            label={_t("common|settings")}
            iconClassName="mx_RoomGeneralContextMenu_iconSettings"
        />
    );

    let leaveOption: JSX.Element | undefined;
    if (roomTags.includes(DefaultTagID.Archived)) {
        leaveOption = (
            <IconizedContextMenuOption
                iconClassName="mx_RoomGeneralContextMenu_iconSignOut"
                label={_t("room|context_menu|forget")}
                className="mx_IconizedContextMenu_option_red"
                onClick={wrapHandler(
                    () =>
                        dis.dispatch({
                            action: "forget_room",
                            room_id: room.roomId,
                        }),
                    onPostForgetClick,
                )}
            />
        );
    } else if (!roomTags.includes(DefaultTagID.ServerNotice)) { // CTalk hide leave for server notice room
        leaveOption = (
            <IconizedContextMenuOption
                onClick={wrapHandler(
                    () =>
                        dis.dispatch({
                            action: "leave_room",
                            room_id: room.roomId,
                        }),
                    onPostLeaveClick,
                )}
                label={_t("action|leave")}
                className="mx_IconizedContextMenu_option_red"
                iconClassName="mx_RoomGeneralContextMenu_iconSignOut"
            />
        );
    }

    // CTalk added
    let deleteRoomOption: JSX.Element | undefined;
    const isOwner = isUserRoleInRoom(ERoomRole.OWNER, room.getMember(room.myUserId || ""));
    if (
        !roomTags.includes(DefaultTagID.Archived) &&
        !roomTags.includes(DefaultTagID.ServerNotice) &&
        isOwner
    ) {
        const onDeleteRoomClick = (ev: ButtonEvent): void => {
            ev.preventDefault();
            ev.stopPropagation();
            dis.dispatch({
                action: "delete_room",
                room_id: room.roomId,
            });
            onFinished();
        };
        const isActiveCall = isActiveLegacyCall(room.roomId);
        deleteRoomOption = (
            <IconizedContextMenuOption
                onClick={onDeleteRoomClick}
                label={_t("ctalk|room_settings|general|delete_room")}
                className="mx_IconizedContextMenu_option_red mx_MessageContextMenu"
                iconClassName="mx_MessageContextMenu_iconRedact"
                disabled={isActiveCall}
            />
        );
    }

    let addToFolderOption: JSX.Element | undefined;
    if (!isDeletedOrDeactivatedDM(room)) {
        addToFolderOption = (
            <IconizedContextMenuOption
                iconClassName="ck_RoomTile_iconAddToFolder"
                label={_t("ctalk|settings|folders|context_add_to_folder")}
                onClick={(ev: ButtonEvent): void => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    dis.dispatch({
                        action: "add_room_to_folder",
                        room_id: room.roomId,
                    });
                    onFinished();
                }}
            />
        );
    }

    const { color } = useUnreadNotifications(room);
    const markAsReadOption: JSX.Element | null =
        color > NotificationColor.None ? (
            <IconizedContextMenuCheckbox
                onClick={(): void => {
                    clearRoomNotification(room, cli);
                    onFinished?.();
                }}
                active={false}
                label={_t("room|context_menu|mark_read")}
                iconClassName="mx_RoomGeneralContextMenu_iconMarkAsRead"
            />
        ) : null;

    const developerModeEnabled = useSettingValue<boolean>("developerMode");
    const developerToolsOption = developerModeEnabled ? (
        <DeveloperToolsOption onFinished={onFinished} roomId={room.roomId} />
    ) : null;

    return (
        <IconizedContextMenu {...props} onFinished={onFinished} className="mx_RoomGeneralContextMenu" compact>
            <IconizedContextMenuOptionList>
                {markAsReadOption}
                {!roomTags.includes(DefaultTagID.Archived) && (
                    <>
                        {favoriteOption}
                        {/* CTalk hidden */}
                        {/* {lowPriorityOption} */}
                        {inviteOption}
                        {copyLinkOption}
                        {autoDeleteButton}
                        {addToFolderOption}
                        {settingsOption}
                    </>
                )}
                {developerToolsOption}
            </IconizedContextMenuOptionList>
            <IconizedContextMenuOptionList red>
                {leaveOption}
                {deleteRoomOption}
            </IconizedContextMenuOptionList>
        </IconizedContextMenu>
    );
};
