export const MENTION_BLACK_LIST = ['Deactivated User'];

export const SETTING_KEYS = {
    ALLOW_TO_EXPORT_CHAT: "ALLOW_TO_EXPORT_CHAT",
    FOLDER_LIMIT_ROOMS: "FOLDER_LIMIT_ROOMS",
    FOLDER_LIMIT: "FOLDER_LIMIT",
};

export const SETTING_ROOM_KEYS = {
    ALLOW_CONFIG_WL_IP: "allow_config_wl_ip",
};

export const LocalStorageKey = {
    USERNAME: "username",
    USERID: "userid",
    MULTI_FACTOR_STATE: "ctalk_two_fa",
    JITSI_CALLER_DEVICE: 'ck_jitsi_caller_device',
};

export const OPEN_DIMENSION_TYPE = {
    WIDGET: 'widget',
    BOT: 'bot',
};

export const CTalkRelatesToKeys = {
    C_RELATES_TO: 'c.relates_to',
    C_FORWARDED_FROM: 'c.forwarded_from',
};

export const ActionEvent = {
    ADD_NEW: "ADD_NEW",
    DELETE_EVENT_ID: "DELETE_EVENT_ID",
    DELETE_ORIGINAL_EVENT: "DELETE_ORIGINAL_EVENT",
};

export const EventContentKey = {
    ALLOW_CONFIG_WHITELIST_IP: "allow_config_wl_ip",
};

export const EventContentType = {
    ROOM_SETTING: "Room.settings",
};

export const CharacterLimitLength = 1024;
