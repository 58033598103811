import { Room } from "matrix-js-sdk/src/matrix";
import { NotificationColor } from "matrix-react-sdk/src/stores/notifications/NotificationColor";
import { arrayDiff } from "matrix-react-sdk/src/utils/arrays";
import { RoomNotificationState } from "matrix-react-sdk/src/stores/notifications/RoomNotificationState";
import { NotificationState, NotificationStateEvents } from "matrix-react-sdk/src/stores/notifications/NotificationState";
import { FetchRoomFn } from "matrix-react-sdk/src/stores/notifications/ListNotificationState";
import { DefaultTagID } from "matrix-react-sdk/src/stores/room-list/models";
import RoomListStore from "matrix-react-sdk/src/stores/room-list/RoomListStore";

export class ChatFolderNotificationState extends NotificationState {
    public rooms: Room[] = [];
    private states: { [folderId: string]: RoomNotificationState } = {};

    public constructor(private getRoomFn: FetchRoomFn) {
        super();
    }

    public get symbol(): string | null {
        return this._color === NotificationColor.Unsent ? "!" : null;
    }

    public setRooms(rooms: Room[]): void {
        const oldRooms = this.rooms;
        const diff = arrayDiff(oldRooms, rooms);
        this.rooms = rooms;
        for (const oldRoom of diff.removed) {
            const state = this.states[oldRoom.roomId];
            if (!state) continue; // We likely just didn't have a badge (race condition)
            delete this.states[oldRoom.roomId];
            state.off(NotificationStateEvents.Update, this.onRoomNotificationStateUpdate);
        }
        for (const newRoom of diff.added) {
            const state = this.getRoomFn(newRoom);
            state.on(NotificationStateEvents.Update, this.onRoomNotificationStateUpdate);
            this.states[newRoom.roomId] = state;
        }

        this.calculateTotalState();
    }

    public getFirstRoomWithNotifications(): string | undefined {
        return Object.values(this.states).find((state) => state.color >= this.color)?.room.roomId;
    }

    public destroy(): void {
        super.destroy();
        for (const state of Object.values(this.states)) {
            state.off(NotificationStateEvents.Update, this.onRoomNotificationStateUpdate);
        }
        this.states = {};
    }

    private onRoomNotificationStateUpdate = (): void => {
        this.calculateTotalState();
    };

    private calculateTotalState(): void {
        const snapshot = this.snapshot();

        this._count = 0;
        this._color = NotificationColor.None;
        for (const [roomId, state] of Object.entries(this.states)) {
            const room = this.rooms.find((r) => r.roomId === roomId);
            const roomTags = room ? RoomListStore.instance.getTagsForRoom(room) : [];

            // We ignore unreads in LowPriority rooms, see https://github.com/vector-im/element-web/issues/16836
            if (roomTags.includes(DefaultTagID.LowPriority) && state.color === NotificationColor.Bold) continue;

            this._count += state.count;
            this._color = Math.max(this.color, state.color);
        }

        // finally, publish an update if needed
        this.emitIfUpdated(snapshot);
    }
}
