import React, { ComponentProps, lazy, Suspense } from "react";
import { ISendEventResponse, MatrixClient } from "matrix-js-sdk/src/matrix";
import { SendMessageParams } from "matrix-react-sdk/src/components/views/rooms/wysiwyg_composer/utils/message";
import { retry } from "matrix-react-sdk/src/utils/promise";
import { IRoomState } from "matrix-react-sdk/src/components/structures/RoomView";
import EditorStateTransfer from "matrix-react-sdk/src/utils/EditorStateTransfer";

// Due to issues such as https://github.com/vector-im/element-web/issues/25277, we add retry
// attempts to all of the dynamic imports in this file
const RETRY_COUNT = 3;
const SendComposer = lazy(() => retry(() => import("./CSendWysiwygComposer"), RETRY_COUNT));
const CaptionComposer = lazy(() => retry(() => import("./CCaptionWysiwygComposer"), RETRY_COUNT));
export const dynamicImportSendMessage = async (
    message: string,
    isHTML: boolean,
    params: SendMessageParams,
): Promise<ISendEventResponse | undefined> => {
    const { sendMessage } = await retry(() => import("./utils/messageHandler"), RETRY_COUNT);

    return sendMessage(message, isHTML, params);
};

interface EditMessageParams {
    mxClient: MatrixClient;
    roomContext: IRoomState;
    editorStateTransfer: EditorStateTransfer;
}

export const dynamicImportEditMessage = async (
    message: string,
    params: EditMessageParams,
): Promise<ISendEventResponse | undefined> => {
    const { editMessage } = await retry(() => import("./utils/messageHandler"), RETRY_COUNT);

    return editMessage(message, params);
};

export function CDynamicImportSendWysiwygComposer(props: ComponentProps<typeof SendComposer>): JSX.Element {
    return (
        <Suspense fallback={<div />}>
            <SendComposer {...props} />
        </Suspense>
    );
}

export function CDynamicImportCaptionWysiwygComposer(props: ComponentProps<typeof CaptionComposer>): JSX.Element {
    return (
        <Suspense fallback={<div />}>
            <CaptionComposer {...props} />
        </Suspense>
    );
}

