import { IContent } from "matrix-js-sdk/src/models/event";
import { CTalkRelatesToKeys } from "@ctalk/constants";
import { Room } from "matrix-js-sdk/src/matrix";
import * as cheerio from "cheerio";
import { removeMarkdown } from '@ctalk/helpers/StringHelper';

const DEACTIVATED_NAME = "Deactivated User";

export function isDesktopMode(): boolean {
    return window.navigator.userAgent.indexOf('Electron') > 0;
}

export function isSafari(): boolean {
    return window.navigator.vendor?.indexOf('Apple') > -1 &&
        window.navigator.userAgent?.indexOf('CriOS') == -1 &&
        window.navigator.userAgent?.indexOf('FxiOS') == -1;
}

export function shortenId(id: string | null): string | null {
    return id && id.substring(
        0,
        id.indexOf(':') > 0 && !id.includes(' ') ?
            id.indexOf(':') :
            id.length,
    );
}

export function shortenUserId(input: string | null | undefined): string | null | undefined {
    return typeOfUserId(input) ? prefixOfId(input) : input;
}

export function shortenRoomId(input: string | null | undefined): string | null | undefined {
    return typeOfRoomId(input) ? prefixOfId(input) : input;
}

export function typeOfUserId(input: string | null | undefined): boolean {
    return !!input && input.startsWith('@') && input.indexOf(':') > 0;
}

export function typeOfRoomId(input: string | null | undefined): boolean {
    return !!input && (input.startsWith('#') || input.startsWith('!')) && input.indexOf(':') > 0;
}

export function prefixOfId(input: string | null | undefined): string | undefined {
    return input?.match(/([^:]+)/)?.[0];
}

export function isBotUser(userId: string): boolean {
    return !!userId && userId.startsWith('@bot') && userId.indexOf(':') > 0;
}

export function isServerNoticeUser(userId: string): boolean {
    return !!userId && userId.startsWith('@server') && userId.indexOf(':') > 0;
}

export function getForwardedEventInfo(content: IContent): string | undefined {
    return content[CTalkRelatesToKeys.C_RELATES_TO]?.[CTalkRelatesToKeys.C_FORWARDED_FROM];
}

export function isDeactivatedRoom(room: Room): boolean{
    return room.name === DEACTIVATED_NAME;
}

export function isInViewPort(eventId?: string): boolean {
    const selectedEvent = document.querySelector(`[data-event-id="${eventId}"]`);
    if (!selectedEvent) {
        return false;
    }
    const rect = selectedEvent.getBoundingClientRect();

    const elementCenterX = rect.left + rect.width / 2;
    const elementCenterY = rect.top + rect.height / 2;

    // Check if the center point is within the viewport
    const isCenterVisibleX = elementCenterX >= 0 && elementCenterX <= window.innerWidth;
    const isCenterVisibleY = elementCenterY >= 0 && elementCenterY <= (window.innerHeight - 100);

    return isCenterVisibleX && isCenterVisibleY;
}

export function getPlaintext(htmlStr: string): string {
    return cheerio.load(
        htmlStr
            .replaceAll(/<br\s*\/?>\n*/gmi, '\n')
            .replaceAll(/<hr\s*\/?>/gmi, '\n')

    ).text().trim();
}

export function getPlainSkipReply(htmlStr: string): string {
    const closeReplyTag = '</mx-reply>';
    const index = htmlStr.indexOf(closeReplyTag);
    if (index < 0) {
        return getPlaintext(htmlStr);
    }
    const excludedReply = htmlStr.slice(index + closeReplyTag.length);
    return getPlaintext(excludedReply);
}

export function getContentForCopying(content: IContent): string | undefined {
    let textToCopy;
    // Copy caption
    // msgtype is a file, image, audio, video that may have a description
    if (content.description) {
        textToCopy = content.description;
    } else if (content.msgtype === 'm.text' || (content.filename && content.body)) {
        if (content.formatted_body) {
            textToCopy = getPlainSkipReply(content.formatted_body);
            //} else if () { // more condition here
        } else {
            textToCopy = removeMarkdown(content.body); // plain text message
        }
    }
    return textToCopy;
}

export function safeStringify(obj: any): string {
    try {
        return JSON.stringify(obj, null, 2);
    } catch (e) {
        const o: any = {};
        Object.keys(obj).map((k) => {
            if (typeof obj[k] === 'function') {
                o[k] = `${obj[k].name}()`;
            } else if (typeof obj[k] === 'object') {
                o[k] = Object.keys(obj[k]);
            } else {
                o[k] = obj[k];
            }
        });
        return JSON.stringify(o, null, 2);
    }
}
