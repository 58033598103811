import { clamp } from "lodash";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { logger } from "matrix-js-sdk/src/logger";
import EditorModel from "matrix-react-sdk/src/editor/model";

import { SerializedPart } from "../../editor/parts";

interface IHistoryItem {
    parts: SerializedPart[];
    eventId?: string;
}

export interface IHistoryItems {
    reply: IHistoryItem | null;
    edit: IHistoryItem | null;
    base: IHistoryItem | null;
}

export default class SendMessageHistoryManager {
    public history: Array<IHistoryItems> = [];
    public prefix: string;
    public lastIndex = 0; // used for indexing the storage
    public currentIndex = 0; // used for indexing the loaded validated history Array

    public constructor(roomId: string, prefix: string) {
        this.prefix = prefix + roomId;

        // TODO: Performance issues?
        let index = 0;
        let itemJSON;

        while ((itemJSON = sessionStorage.getItem(`${this.prefix}[${index}]`))) {
            try {
                this.history.push(JSON.parse(itemJSON));
            } catch (e) {
                logger.warn("Throwing away unserialisable history", e);
                break;
            }
            ++index;
        }
        this.lastIndex = this.history.length - 1;
        // reset currentIndex to account for any unserialisable history
        this.currentIndex = this.lastIndex + 1;
    }

    public static createItem(
        model: EditorModel,
        currentItem: IHistoryItems | null,
        replyEvent?: MatrixEvent,
        editEvent?: MatrixEvent
    ): IHistoryItems {
        return {
            reply: replyEvent
                ? { parts: model.serializeParts(), eventId: replyEvent.getId() }
                : currentItem?.reply || null,
            edit: editEvent
                ? { parts: model.serializeParts(), eventId: editEvent.getId() }
                : currentItem?.edit || null,
            base:
                !replyEvent && !editEvent
                ? { parts: model.serializeParts(), eventId: undefined }
                : currentItem?.base || null,
        };
    }

    // Update the reply field
    public getReplyDraft(model: EditorModel, currentItem: IHistoryItems, replyEvent?: MatrixEvent, ): IHistoryItems | null {
        if (!replyEvent) {
            return null;
        }
        return {
            ...currentItem,
            reply: { parts: model.serializeParts(), eventId: replyEvent.getId() },
        };
    }

    // Update the edit field
    public getEditDraft(model: EditorModel, currentItem: IHistoryItems, editEvent?: MatrixEvent): IHistoryItems | null {
        if (!editEvent) {
            return null;
        }
        return {
            ...currentItem,
            edit: { parts: model.serializeParts(), eventId: editEvent.getId() },
        };
    }

    // Update the draft field
    public getBaseDraft(model: EditorModel, currentItem: IHistoryItems): IHistoryItems {
        return {
            ...currentItem,
            base: { parts: model.serializeParts(), eventId: undefined },
        };
    }

    public save(editorModel: EditorModel, replyEvent?: MatrixEvent, editEvent?: MatrixEvent): void {
        const currentItem = this.history[this.currentIndex] || null;
        const item = SendMessageHistoryManager.createItem(editorModel, currentItem, replyEvent, editEvent);
        this.history.push(item);
        this.currentIndex = this.history.length;
        this.lastIndex += 1;
        sessionStorage.setItem(`${this.prefix}[${this.lastIndex}]`, JSON.stringify(item));
    }

    public getItem(offset: number): IHistoryItems {
        this.currentIndex = clamp(this.currentIndex + offset, 0, this.history.length - 1);
        return this.history[this.currentIndex];
    }
}
