import { EEventType } from '@ctalk/enums/event-type.enum';
import { MatrixClient } from "matrix-js-sdk/src/matrix";

import { IChatFolder } from "../../../interfaces/chat_folders/IChatFolder";

export function getChatFolders(cli: MatrixClient): IChatFolder[] {
    const accountData = cli.getAccountData(EEventType.ChatFolder);
    return accountData?.getContent()?.folders || [];
}

export function updateChatFolders(cli: MatrixClient, data: IChatFolder[]): Promise<{}> {
    return cli.setAccountData(EEventType.ChatFolder, { folders: data });
}
